<template>
  <div v-if="items" class="discount-catalog__items">
    <product-card
      v-for="item in items"
      :key="item.id"
      :item="item"
      :fromListName="fromListName"
    />
  </div>
</template>

<script>
export default {
  name: "ProductsList",
  components: {
    ProductCard: () => import("@/components/products/ProductCard.vue"),
  },
  props: {
    items: {
      type: Array,
      requred: true,
    },
    fromListName: {
      type: String,
      default: "",
    },
  },
};
</script>
